import { FC } from "react";
import Home from "./Home";
import TotalUsersWidget from "./TotalUsersWidget";
import { HomeSection } from "./HomeSection";
import TotalActiveUsersWidget from "./TotalActiveUsersWidget";
import TotalLocationsWidget from "./TotalLocationsWidget";
import ActiveManagersWidget from "./ActiveManagersWidget";
import AssignmentsCompletionWidget from "./AssignmentsCompletionWidget";
import RecentlyEditedModulesWidget from "./RecentlyEditedModulesWidget";
import RecentAssignmentsWidget from "./RecentAssignmentsWidget";
import OverviewOfYourLocationsWidgets from "@src/components/home/OverviewOfYourLocationsWidgets";
import ActiveLanguagesWidget from "./ActiveLanguagesWidget";
import AvgTrainingFeedbackWidget from "./AvgTrainingFeedbackWidget";
import SHPProgressWidget from "./SHPProgressWidget";
import LocationAssignmentLeaderboardWidget from "./LocationAssignmentLeaderboardWidget";

const AdminHome: FC = () => {
  return (
    <Home>
      <div className="flex flex-col gap-4">
        <OverviewOfYourLocationsWidgets />
        <RecentlyEditedModulesWidget />
        <HomeSection title="Overview">
          <div className="grid grid-cols-2 gap-4">
            <TotalLocationsWidget />
            <TotalUsersWidget />
          </div>
        </HomeSection>
        <HomeSection title="Usage">
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            <TotalActiveUsersWidget />
            <ActiveManagersWidget />
          </div>
        </HomeSection>
        <HomeSection title="Assignments">
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <AssignmentsCompletionWidget />
            <LocationAssignmentLeaderboardWidget />
            <ActiveLanguagesWidget />
            <SHPProgressWidget />
            <RecentAssignmentsWidget />
            <AvgTrainingFeedbackWidget />
          </div>
        </HomeSection>
      </div>
    </Home>
  );
};

export default AdminHome;
