import Widget from "./Widget";
import { gql } from "@apollo/client";
import Text from "@src/ui/text";

import { Skeleton } from "@src/ui/skeleton";
import { useAdminEmployeesCountQuery } from "./TotalUsersWidget.generated";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import { Badge } from "@src/ui/badge";
import { useMemo } from "react";

const TotalUsersWidget: React.FC = () => {
  const variables = useMemo(() => {
    const pastWeekStartDate = new Date(
      new Date().setDate(new Date().getDate() - 7),
    );
    return {
      pastWeekStartDate: pastWeekStartDate.toISOString(),
    };
  }, []);
  const { data, loading } = useAdminEmployeesCountQuery({
    variables,
  });
  const employeeCountChange =
    data?.PastWeekEmployees?.totalCount && data?.TotalEmployees?.totalCount
      ? data.TotalEmployees.totalCount - data.PastWeekEmployees.totalCount
      : 0;

  return (
    <Widget
      title="Total users"
      icon={<PinsOutlineIcon className="text-muted-foreground" />}
      href={{ pathname: "/users" }}
    >
      {loading ? (
        <Skeleton className="h-6 w-10 rounded-md" />
      ) : (
        <div className="flex flex-row items-center gap-1 align-middle">
          <Text type="H1" fontWeight="SemiBold">
            {data?.TotalEmployees?.totalCount}
          </Text>
          <EmployeeCountChange diff={employeeCountChange} />
        </div>
      )}
    </Widget>
  );
};

const EmployeeCountChange: React.FC<{ diff: number }> = ({ diff }) => {
  const zero = diff === 0;
  const diffText = zero ? "0" : diff > 0 ? `+${diff}` : `${diff}`;

  return (
    <>
      <Badge
        variant={diff > 0 ? "success" : diff < 0 ? "destructive" : "secondary"}
      >
        <Text type="P3" fontWeight="Medium">
          {diffText}
        </Text>
      </Badge>
      <Text type="P3" className="text-gray-8">
        this week
      </Text>
    </>
  );
};

gql`
  query AdminEmployeesCount($pastWeekStartDate: Date!) {
    TotalEmployees: AdminEmployees {
      totalCount
    }
    PastWeekEmployees: AdminEmployees(startDate: $pastWeekStartDate) {
      totalCount
    }
  }
`;

export default TotalUsersWidget;
