import useActionPermissions from "@src/hooks/useActionPermissions";
import { useModal } from "@src/hooks/useModal";
import { FC, useCallback, useMemo } from "react";
import NoPermissionsTooltipWrapper from "../ui/NoPermissionsTooltipWrapper";
import { LocationGroupType } from "@src/types.generated";
import LocationGroupModal from "./LocationGroupModal";
import { locationGroupTypeDisplayName } from "./constants";
import { Button } from "@src/ui/button";
import PlusIcon from "@src/ui/icons/18px/plus";

type Props = {
  type: LocationGroupType;
};

const NewLocationGroupButton: FC<Props> = (props) => {
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const buttonTitle = useMemo(() => {
    return `New ${locationGroupTypeDisplayName[props.type]}`;
  }, [props.type]);
  const handleOnClick = useCallback(() => {
    showModal(<LocationGroupModal type={props.type} />);
  }, [showModal, props.type]);
  return (
    <NoPermissionsTooltipWrapper
      hasPermission={userActionPermissions?.changeOrgSettings}
      caretPosition="Top"
      tooltipSide="bottom"
    >
      <Button
        disabled={!userActionPermissions?.changeOrgSettings}
        onClick={handleOnClick}
      >
        <PlusIcon />
        {buttonTitle}
      </Button>
    </NoPermissionsTooltipWrapper>
  );
};

export default NewLocationGroupButton;
