import Widget from "./Widget";
import { gql } from "@apollo/client";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import { Skeleton } from "@src/ui/skeleton";
import { useAssignmentCompletion__HomeWidgetQuery } from "./AssignmentsCompletionWidget.generated";
import Text from "@src/ui/text";
import CheckDoubleIcon from "@src/ui/icons/18px/check-double";
import { format, parse } from "date-fns";

const chartConfig = {
  totalAssignments: {
    label: "Assigned",
  },
  completedAssignments: {
    label: "Completed",
  },
};

const AssignmentsCompletionWidget: React.FC = () => {
  const { data, loading } = useAssignmentCompletion__HomeWidgetQuery();

  const totalAssignments = data?.AssignmentCompletion.reduce(
    (acc: number, curr: { totalAssignments: number }) =>
      acc + curr.totalAssignments,
    0,
  );
  const completedAssignments = data?.AssignmentCompletion.reduce(
    (acc: number, curr: { completedAssignments: number }) =>
      acc + curr.completedAssignments,
    0,
  );

  const formatTooltipLabel = (dateString: string) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    return format(date, "MMM yy");
  };

  const formattedData = data?.AssignmentCompletion?.map?.((item) => ({
    ...item,
    tooltipLabel: formatTooltipLabel(item.tooltipLabel),
  }));

  // Compute first and last tooltip labels for the X-axis ticks
  const ticks =
    formattedData && formattedData.length > 0
      ? [
          formattedData[0].tooltipLabel,
          formattedData[formattedData.length - 1].tooltipLabel,
        ]
      : [];

  return (
    <Widget
      title={
        <Text type="P2" fontWeight="SemiBold">
          Completed Assignments vs total
        </Text>
      }
      icon={<CheckDoubleIcon />}
    >
      {loading ? (
        <Skeleton className="h-[250px] w-full rounded-xl" />
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <div className="mr-4 flex flex-col self-end">
              <Text type={"H1"}>{completedAssignments?.toLocaleString()}</Text>
              <Text type="P3" fontWeight="Medium" className="text-gray-7">
                vs{" "}
                <span className="text-gray-10">
                  {totalAssignments?.toLocaleString()}
                </span>{" "}
                Assigned
              </Text>
            </div>
            <ChartContainer config={chartConfig} className="h-[200px]">
              <LineChart accessibilityLayer data={formattedData || []}>
                <CartesianGrid
                  vertical={true}
                  horizontal={false}
                  strokeDasharray="6 6"
                />
                <XAxis
                  dataKey="axisLabel"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  xAxisId={0}
                  height={0}
                />
                <XAxis
                  dataKey="tooltipLabel"
                  ticks={ticks}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  allowDuplicatedCategory={false}
                  type="category"
                  xAxisId={1}
                  interval="preserveStartEnd" // Ensures first and last labels are shown
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent />}
                  isAnimationActive={false}
                />
                <Line
                  dataKey="totalAssignments"
                  type="linear"
                  strokeWidth={2}
                  dot={false}
                  stroke="#4778F5"
                  isAnimationActive={false}
                />
                <Line
                  dataKey="completedAssignments"
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  stroke="#40D986"
                  isAnimationActive={false}
                />
              </LineChart>
            </ChartContainer>
          </div>
        </>
      )}
    </Widget>
  );
};

gql`
  query AssignmentCompletion__HomeWidget {
    AssignmentCompletion {
      libraryItemId
      day
      totalAssignments
      completedAssignments
      axisLabel
      tooltipLabel
    }
  }
`;

export default AssignmentsCompletionWidget;
