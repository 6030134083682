import React from "react";
import useActionPermissions from "@src/hooks/useActionPermissions";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import NewRoleModal from "@components/modals/NewRoleModal";
import PlusIcon from "@src/ui/icons/18px/plus";
import { Button } from "@src/ui/button";

const NewRoleButton: React.FC = () => {
  const { userActionPermissions, loading: userActionPermissionsLoading } =
    useActionPermissions();
  return (
    <NewRoleModal
      trigger={
        <NoPermissionsTooltipWrapper
          hasPermission={userActionPermissions?.changeOrgSettings}
          caretPosition="Top"
          tooltipSide="bottom"
        >
          <Button disabled={userActionPermissionsLoading}>
            <PlusIcon />
            New Role
          </Button>
        </NoPermissionsTooltipWrapper>
      }
    />
  );
};

export default NewRoleButton;
