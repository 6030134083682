import { DateTime } from "luxon";
import { ComplianceType } from "@src/types.generated";
import {
  PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
  PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment,
  ComplianceSummaryFragment,
} from "./PremiumPathDetailCertificatesTab.generated";
import { orderBy } from "lodash";
import { formatDateOnlyShowYearIfNotCurrent } from "@src/utils/dates";
import { parseISO } from "date-fns";

export const getCertifiedBy = (
  record: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): string => {
  const attempt = getMostRecentComplianceAttempt(record);
  if (!attempt) {
    return "";
  }
  if (attempt.importedFromExternalSource) {
    return "Import";
  } else {
    return "Assignment";
  }
};

export const getMostRecentComplianceAttempt = (
  complianceRecord: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment => {
  return orderBy(complianceRecord.attempts, "createdAt", "desc")[0];
};

export const getMostRecentCompletedComplianceAttempt = (
  complianceRecord: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment => {
  return orderBy(
    complianceRecord.attempts.filter(
      (c: PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment) =>
        c.compliantUntil,
    ),
    "createdAt",
    "desc",
  )[0];
};

export const formatCompliantUntil = (
  compliantUntil: string | null | undefined,
): string => {
  if (!compliantUntil) return "Not compliant";
  const dateStr = formatDateOnlyShowYearIfNotCurrent(parseISO(compliantUntil));
  if (isInFuture(compliantUntil)) return dateStr;
  return `${dateStr} (expired)`;
};

export const isInFuture = (date: string | null | undefined): boolean => {
  if (!date) return false;
  return DateTime.fromISO(date).diffNow().as("days") > 0;
};

export const COMPLIANCE_TYPE_COPY: { [key in ComplianceType]: string } = {
  [ComplianceType.SexualHarassmentPrevention]: "Sexual Harassment Prevention",
  [ComplianceType.FoodHandler]: "Food Handler",
  [ComplianceType.FoodManager]: "Food Manager",
  [ComplianceType.Allertrain]: "Allertrain",
  [ComplianceType.HumanTraffickingAwarenessCa]: "Human Trafficking Awareness",
  [ComplianceType.CaWorkplaceViolencePreventionNonsupervisor]:
    "Workplace Violence Prevention Training (California) for Non-Supervisors",
  [ComplianceType.CaWorkplaceViolencePreventionSupervisor]:
    "Workplace Violence Prevention Training (California) for Supervisors",
};

export const getCompliantPercent = (
  complianceRecords: ComplianceSummaryFragment[] | undefined,
): number | undefined => {
  if (!complianceRecords || !complianceRecords.length) return undefined;
  const compliant = complianceRecords.filter(
    (x) => isInFuture(x.compliantUntil) && x.compliantUntil,
  );
  return compliant?.length
    ? Math.round((compliant.length / complianceRecords.length) * 100)
    : 0;
};
