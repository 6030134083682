import { getLocationDetailRoute } from "@components/people/utils/getDetailRoute";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import React, { FC, useCallback, useMemo } from "react";
import { gql } from "@apollo/client";
import { addressFragmentToString } from "@src/deprecatedDesignSystem/components/AddressField";
import { LocationGroupType, LocationsInput } from "@src/types.generated";
import useHiddenLocationGroupTypes from "@components/home/hooks/useHiddenLocationGroupTypes";
import { orderBy } from "lodash";
import {
  LocationsTable_LocationFragment,
  useLocationsTable_LocationsQuery,
} from "@components/home/LocationsTable.generated";
import { locationGroupTypeDisplayName } from "@components/home/constants";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import useActionPermissions from "@hooks/useActionPermissions";
import LocationModal from "@components/modals/LocationModal";
import { useModal } from "@hooks/useModal";
import LocationGroupTableAvatarTitleCell from "@src/components/locations/LocationGroupTableAvatarTitleCell";

type LocationsTableColumn = "name" | "members" | "address" | LocationGroupType;
const COLUMNS: LocationsTableColumn[] = [
  "name",
  LocationGroupType.Region,
  LocationGroupType.Brand,
  LocationGroupType.Franchise,
  "members",
  "address",
];

type Props = {
  input?: Partial<LocationsInput>;
  hiddenColumns?: LocationsTableColumn[];
  rightAction?: React.ReactNode;
  hideTitle?: boolean;
  hideCount?: boolean;
};

const LocationsTable: FC<Props> = (props) => {
  const { data, loading } = useLocationsTable_LocationsQuery({
    variables: {
      input: props.input || {},
    },
  });
  const allItems: LocationsTable_LocationFragment[] = useMemo(() => {
    return data?.Locations.objects || [];
  }, [data?.Locations.objects]);
  const hiddenLgTypes = useHiddenLocationGroupTypes();
  const hiddenColumns = useCallback(() => {
    return [
      ...COLUMNS.filter((col) =>
        hiddenLgTypes.includes(col as LocationGroupType),
      ),
      ...(props.hiddenColumns || []),
    ];
  }, [hiddenLgTypes, props.hiddenColumns]);
  const { userActionPermissions } = useActionPermissions();
  const { showModal } = useModal();
  return (
    <Table
      title={props.hideTitle ? undefined : "Locations"}
      showCount={!props.hideCount}
      dataTestId={"locations-table"}
      allItems={allItems}
      itemId={(item) => item.id}
      allColumns={COLUMNS}
      columnTitles={{
        name: "Name",
        members: "People",
        address: "Address",
        ...locationGroupTypeDisplayName,
      }}
      hiddenColumns={hiddenColumns}
      fuseSearchConfig={{
        threshold: 0.2,
        keys: [
          {
            name: "name",
            getFn: (x) => x.name,
          },
          {
            name: "address",
            getFn: (x) => (x.address ? addressFragmentToString(x.address) : ""),
          },
        ],
      }}
      rowStyles={{
        height: 24,
      }}
      rightAction={props.rightAction}
      noDataEmptyState={"No locations"}
      rowClickLink={(location) => {
        return getLocationDetailRoute(location.id);
      }}
      loading={loading}
      sortingFns={{
        name: (x) => x.name,
        members: (x) => x.userCount,
        address: (x) => (x.address ? addressFragmentToString(x.address) : null),
        [LocationGroupType.Region]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Region);
        },
        [LocationGroupType.Franchise]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Franchise);
        },
        [LocationGroupType.Brand]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Brand);
        },
      }}
      defaultSort={{
        column: "name",
        descending: false,
      }}
      rowHeight={50}
      overflowMenu={(x) => {
        if (!userActionPermissions?.changeOrgSettings) {
          return null;
        }
        return (
          <>
            <ContextMenuItem
              label={"Edit location"}
              icon={"pencil"}
              onSelect={() => {
                showModal(<LocationModal id={x.id} />);
              }}
            />
          </>
        );
      }}
      renderFns={{
        name: (location) => {
          return (
            <LocationGroupTableAvatarTitleCell
              name={location.name}
              locationGroupType={"LOCATION"}
            />
          );
        },
        members: (location) => {
          return location.userCount;
        },
        address: (x) => (x.address ? addressFragmentToString(x.address) : null),
        [LocationGroupType.Region]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Region);
        },
        [LocationGroupType.Franchise]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Franchise);
        },
        [LocationGroupType.Brand]: (location) => {
          return getLgTypeStr(location, LocationGroupType.Brand);
        },
      }}
    />
  );
};

function getLgTypeStr(
  location: LocationsTable_LocationFragment,
  type: LocationGroupType,
): string | null {
  const membershipsOfType = location.locationGroupMemberships
    .filter((lgm) => lgm.locationGroup.type === type)
    .map((x) => x.locationGroup.name);
  return membershipsOfType.length > 0
    ? orderBy(membershipsOfType, (x) => x).join(", ")
    : null;
}

export default LocationsTable;

gql`
  query LocationsTable_Locations($input: LocationsInput) {
    Locations(input: $input) {
      objects {
        ...LocationsTable_Location
      }
    }
  }

  fragment LocationsTable_Location on Location {
    id
    name
    userCount
    address {
      ...Address
    }
    locationGroupMemberships {
      locationGroup {
        id
        type
        name
      }
    }
  }
`;
