import React, { FC, useMemo } from "react";
import { MultiSelect } from "@ui/multi-select";
import { useGetAllEmployeesQuery } from "@components/ui/operations.generated";
import { MinimalEmployeeFragment } from "../ui/operations.generated";
import { UserType } from "@src/types.generated";
import CircleUserOutlineIcon from "@src/ui/icons/18px/circle-user-outline";

type Props = {
  defaultUserIds?: string[];
  setEmployees: (employees: MinimalEmployeeFragment[]) => void;
  excludeEmployeeIds?: Set<string>;
  placeholder?: string;
  userTypes?: UserType[];
};

export const UserMultiSelect: FC<Props> = ({
  defaultUserIds,
  setEmployees,
  excludeEmployeeIds,
  placeholder = "Search people...",
  userTypes = [],
}) => {
  const { data } = useGetAllEmployeesQuery({
    variables: {
      userTypes,
    },
  });
  const allEmployees = useMemo(() => data?.Employees.objects || [], [data]);
  const availableEmployees = useMemo(
    () =>
      allEmployees.filter(
        (e) => !excludeEmployeeIds || !excludeEmployeeIds.has(e.id.toString()),
      ),
    [allEmployees, excludeEmployeeIds],
  );
  const options = useMemo(
    () =>
      availableEmployees.map((employee) => ({
        label: employee.name || "",
        value: employee.id.toString(),
        icon: CircleUserOutlineIcon,
      })),
    [availableEmployees],
  );
  const defaultValue = useMemo(() => {
    if (!defaultUserIds) return [];
    const defaultSelectedEmployees = allEmployees.filter((employee) =>
      defaultUserIds.includes(employee.id.toString()),
    );
    return defaultSelectedEmployees.map((e) => e.id.toString());
  }, [allEmployees, defaultUserIds]);
  const handleValueChange = (selectedValues: string[]) => {
    const selectedEmployees = allEmployees.filter((employee) =>
      selectedValues.includes(employee.id.toString()),
    );
    setEmployees(selectedEmployees);
  };
  return (
    <MultiSelect
      variant="secondary"
      options={options}
      onValueChange={handleValueChange}
      defaultValue={defaultValue}
      placeholder={placeholder}
      maxCount={5}
      modalPopover={true}
    />
  );
};

export default UserMultiSelect;
