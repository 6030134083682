import { HomeSection } from "@src/components/home/HomeSection";
import InactiveUsersWidget from "@src/components/home/InactiveUsersWidget";
import UsersNotInvitedWidget from "@src/components/home/NotInvitedUsersWidget";
import UsersNoRoleWidget from "@src/components/home/UsersNoRoleWidget";
import { Card } from "@src/ui/card";
import React from "react";

const OverviewOfYourLocationsWidgets: React.FC = () => {
  return (
    <HomeSection title="Overview of your locations">
      <div className="flex gap-3">
        <Card className="flex-1 self-stretch shadow-none" />
        <InactiveUsersWidget />
        <UsersNotInvitedWidget />
        <UsersNoRoleWidget />
      </div>
    </HomeSection>
  );
};

export default OverviewOfYourLocationsWidgets;
