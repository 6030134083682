import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManagerLeaderboardItem_WidgetFragment = { __typename?: 'ManagerLeaderboardItem', id: string, managerId: number, managerName: string, teamAssignmentsCompleted: number };

export type ActiveAndTotalManagersQueryVariables = Types.Exact<{
  activeSince: Types.Scalars['Date'];
}>;


export type ActiveAndTotalManagersQuery = { __typename?: 'Query', ActiveManagers: { __typename?: 'Employees', totalCount: number }, TotalManagers: { __typename?: 'Employees', totalCount: number }, AssignmentsCompleted: { __typename?: 'ManagerLeaderboard', objects: Array<{ __typename?: 'ManagerLeaderboardItem', id: string, managerId: number, managerName: string, teamAssignmentsCompleted: number }> } };

export const ManagerLeaderboardItem_WidgetFragmentDoc = gql`
    fragment ManagerLeaderboardItem_Widget on ManagerLeaderboardItem {
  id
  managerId
  managerName
  teamAssignmentsCompleted
}
    `;
export const ActiveAndTotalManagersDocument = gql`
    query ActiveAndTotalManagers($activeSince: Date!) {
  ActiveManagers: AdminEmployees(activeSince: $activeSince, userTypes: [MANAGER]) {
    totalCount
  }
  TotalManagers: AdminEmployees(userTypes: [MANAGER]) {
    totalCount
  }
  AssignmentsCompleted: ManagerLeaderboardPaginated(
    input: {window: WEEK, sort: {column: "teamAssignmentsCompleted", descending: true}}
    pagination: {limit: 3, offset: 0}
  ) {
    objects {
      ...ManagerLeaderboardItem_Widget
    }
  }
}
    ${ManagerLeaderboardItem_WidgetFragmentDoc}`;

/**
 * __useActiveAndTotalManagersQuery__
 *
 * To run a query within a React component, call `useActiveAndTotalManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAndTotalManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAndTotalManagersQuery({
 *   variables: {
 *      activeSince: // value for 'activeSince'
 *   },
 * });
 */
export function useActiveAndTotalManagersQuery(baseOptions: Apollo.QueryHookOptions<ActiveAndTotalManagersQuery, ActiveAndTotalManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveAndTotalManagersQuery, ActiveAndTotalManagersQueryVariables>(ActiveAndTotalManagersDocument, options);
      }
export function useActiveAndTotalManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveAndTotalManagersQuery, ActiveAndTotalManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveAndTotalManagersQuery, ActiveAndTotalManagersQueryVariables>(ActiveAndTotalManagersDocument, options);
        }
export type ActiveAndTotalManagersQueryHookResult = ReturnType<typeof useActiveAndTotalManagersQuery>;
export type ActiveAndTotalManagersLazyQueryHookResult = ReturnType<typeof useActiveAndTotalManagersLazyQuery>;
export type ActiveAndTotalManagersQueryResult = Apollo.QueryResult<ActiveAndTotalManagersQuery, ActiveAndTotalManagersQueryVariables>;