import Text from "@src/ui/text";
import Widget from "@src/components/home/Widget";
import { Skeleton } from "@src/ui/skeleton";
import { gql } from "@apollo/client";
import { useUsersNoRoleWidgetCountQuery } from "@src/components/home/UsersNoRoleWidget.generated";
import UsersNoRoleSheet from "@src/components/home/UsersNoRoleSheet";

const UsersNotInvitedWidget: React.FC = () => {
  const { data, loading } = useUsersNoRoleWidgetCountQuery({
    fetchPolicy: "cache-and-network",
  });
  const peopleWithoutRoleCount = data?.People.totalCount || 0;
  return (
    <Widget title="No role" className="flex-1 self-stretch shadow-none">
      {loading ? (
        <Skeleton className="h-6 w-10 rounded-md" />
      ) : (
        <div className="flex flex-row items-center gap-1 align-middle">
          <UsersNoRoleSheet>
            <Text
              type="H1"
              fontWeight="SemiBold"
              className="cursor-pointer hover:underline"
            >
              {peopleWithoutRoleCount}
            </Text>
          </UsersNoRoleSheet>
        </div>
      )}
    </Widget>
  );
};

gql`
  query UsersNoRoleWidgetCount {
    People(
      input: {
        hasRole: { value: false }
        userTypes: { value: [EMPLOYEE, MANAGER] }
      }
    ) {
      totalCount
    }
  }
`;

export default UsersNotInvitedWidget;
