import * as React from "react";
import { IconProps } from "../types";

const PinOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M14.779 7.266C14.779 9.888 11.351 14.099 9.775 15.897C9.362 16.368 8.636 16.368 8.224 15.897C6.648 14.1 3.22 9.889 3.22 7.266C3.221 3.776 6.207 1.75 9 1.75C11.793 1.75 14.779 3.776 14.779 7.266Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.25C9.9665 9.25 10.75 8.4665 10.75 7.5C10.75 6.5335 9.9665 5.75 9 5.75C8.0335 5.75 7.25 6.5335 7.25 7.5C7.25 8.4665 8.0335 9.25 9 9.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default PinOutlineIcon;
