import React from "react";
import Text from "@src/ui/text";
import { Card } from "@src/ui/card";
import { cn } from "@src/ui/lib/utils";
import Link from "next/link";
import { Route } from "nextjs-routes";

type Props = {
  title: string | React.ReactElement<typeof Text>;
  icon?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  href?: Route;
};

const Widget: React.FC<Props> = ({
  title,
  icon,
  children,
  className,
  href,
}) => {
  return (
    <Card className={cn("flex flex-col gap-6 p-4", className)}>
      <div className="flex items-center gap-2">
        {icon}
        {href ? (
          <Link href={href} className="hover:underline">
            {typeof title === "string" ? <Text type="P2">{title}</Text> : title}
          </Link>
        ) : typeof title === "string" ? (
          <Text type="P2">{title}</Text>
        ) : (
          title
        )}
      </div>
      {children}
    </Card>
  );
};

export default Widget;
