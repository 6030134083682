import React, { FC } from "react";
import Text from "@ui/text";
import Image from "next/image";
import CodeBranchOutlineIcon from "@ui/icons/18px/code-branch-outline";
import CopyrightOutlineIcon from "@ui/icons/18px/copyright-outline";
import EarthPinOutlineIcon from "@ui/icons/18px/earth-pin-outline";
import { LocationGroupType } from "@src/types.generated";
import { IconProps } from "@ui/icons/types";
import PinOutlineIcon from "@src/ui/icons/18px/pin-outline";

type IconComponentType = React.ForwardRefExoticComponent<
  IconProps & React.RefAttributes<SVGSVGElement>
>;

const LOCATION_GROUP_TYPE_TO_ICON_MAP: Record<
  LocationGroupType | "LOCATION",
  IconComponentType
> = {
  [LocationGroupType.Region]: EarthPinOutlineIcon,
  [LocationGroupType.Franchise]: CodeBranchOutlineIcon,
  [LocationGroupType.Brand]: CopyrightOutlineIcon,
  LOCATION: PinOutlineIcon,
};

type Props = {
  name: string;
  logoUrl?: string;
  locationGroupType: LocationGroupType | "LOCATION";
};

const LocationGroupTableAvatarTitleCell: FC<Props> = ({
  name,
  logoUrl,
  locationGroupType,
}) => {
  const IconComponent = LOCATION_GROUP_TYPE_TO_ICON_MAP[locationGroupType];
  return (
    <div className="flex flex-1 items-center">
      <div className="flex flex-1 items-center gap-2">
        {logoUrl ? (
          <div className="relative size-8 overflow-hidden rounded-lg">
            <Image src={logoUrl} alt={name} layout="fill" objectFit="cover" />
          </div>
        ) : (
          <div className="flex size-8 items-center justify-center overflow-hidden rounded-lg bg-gray-2">
            <IconComponent className="text-muted-foreground" />
          </div>
        )}
        <div className="flex-1">
          <Text className="flex-1" type="P2" fontWeight="Medium" ellipsis>
            {name}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LocationGroupTableAvatarTitleCell;
