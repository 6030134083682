import Text from "@src/ui/text";
import Widget from "@src/components/home/Widget";
import { Skeleton } from "@src/ui/skeleton";
import { gql } from "@apollo/client";
import {
  useNotInvitedUsersWidgetHrisEmployeesQuery,
  useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery,
} from "@src/components/home/NotInvitedUsersWidget.generated";
import NotInvitedUsersSheet from "@src/components/home/NotInvitedUsersSheet";

const UsersNotInvitedWidget: React.FC = () => {
  const { data, loading } = useNotInvitedUsersWidgetHrisEmployeesQuery();
  const hasHrisEmployees =
    data?.HrisEmployees.totalCount && data?.HrisEmployees.totalCount > 0;
  const {
    data: notInvitedHrisEmployeesData,
    loading: notInvitedHrisEmployeesLoading,
  } = useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery();
  const notInvitedHrisEmployeesCount =
    notInvitedHrisEmployeesData?.NotInvitedHrisEmployees.totalCount || 0;
  if (!hasHrisEmployees) return null;
  return (
    <Widget title="Not invited" className="flex-1 self-stretch shadow-none">
      {loading || notInvitedHrisEmployeesLoading ? (
        <Skeleton className="h-6 w-10 rounded-md" />
      ) : (
        <div className="flex flex-row items-center gap-1 align-middle">
          <NotInvitedUsersSheet>
            <Text
              type="H1"
              fontWeight="SemiBold"
              className="cursor-pointer hover:underline"
            >
              {notInvitedHrisEmployeesCount}
            </Text>
          </NotInvitedUsersSheet>
        </div>
      )}
    </Widget>
  );
};

gql`
  query NotInvitedUsersWidgetHrisEmployees {
    HrisEmployees(input: {}) {
      totalCount
    }
  }
  query NotInvitedUsersWidgetNotInvitedHrisEmployees {
    NotInvitedHrisEmployees: HrisEmployees(
      input: { filters: { inviteStatus: { value: false } } }
    ) {
      totalCount
    }
  }
`;

export default UsersNotInvitedWidget;
