import React from "react";
import {
  Sheet,
  SheetActions,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@src/ui/sheet";

import { gql } from "@apollo/client";
import Avatar from "@src/components/ui/Avatar";
import Text from "@src/ui/text";
import PaginatedTable from "@src/deprecatedDesignSystem/components/table/PaginatedTable";
import InviteUsersDropdown from "@src/components/users/InviteUsersDropdown";
import {
  NotInvitedUsersSheetHrisEmployeeFragment,
  useNotInvitedUsersSheetHrisEmployeesLazyQuery,
  useNotInvitedUsersSheetHrisEmployeesQuery,
} from "@src/components/home/NotInvitedUsersSheet.generated";

type Props = {
  children: React.ReactNode;
};

type Column = "name";
const exportColumns = ["name"] as const;
type ExportColumn = (typeof exportColumns)[number];

const NotInvitedUsersSheet: React.FC<Props> = (props) => {
  return (
    <Sheet>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Users not invited</SheetTitle>
          <SheetDescription>
            HRIS employees who have not been invited to Opus
          </SheetDescription>
          <SheetActions>
            <InviteUsersDropdown />
          </SheetActions>
        </SheetHeader>
        <PaginatedTable<
          NotInvitedUsersSheetHrisEmployeeFragment,
          Column,
          typeof useNotInvitedUsersSheetHrisEmployeesQuery,
          typeof useNotInvitedUsersSheetHrisEmployeesLazyQuery,
          ExportColumn
        >
          title={undefined}
          allItemsCountInput={undefined}
          queryHook={useNotInvitedUsersSheetHrisEmployeesQuery}
          lazyQueryHook={useNotInvitedUsersSheetHrisEmployeesLazyQuery}
          getItems={(x) => x?.HrisEmployees.objects ?? []}
          getTotalItemCount={(x) => x?.HrisEmployees.totalCount ?? 0}
          itemId={(x) => x.id}
          defaultSort={{ column: "name", descending: true }}
          allColumns={["name"]}
          columnTitles={{ name: "User" }}
          rowHeight={52}
          renderFns={{
            name: (x) => {
              return (
                <div className="flex flex-row items-center gap-2">
                  <Avatar
                    person={{ id: x.id, name: `${x.firstName} ${x.lastName}` }}
                  />
                  <div>
                    <Text type="P2" fontWeight="Medium">
                      {x.firstName} {x.lastName}
                    </Text>
                  </div>
                </div>
              );
            },
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

gql`
  query NotInvitedUsersSheetHrisEmployees(
    $input: HrisEmployeesInput!
    $pagination: PaginationInput
  ) {
    HrisEmployees(input: $input, pagination: $pagination) {
      totalCount
      objects {
        ...NotInvitedUsersSheetHrisEmployee
      }
    }
  }
  fragment NotInvitedUsersSheetHrisEmployee on HrisEmployee {
    id
    firstName
    lastName
    phoneNumber
  }
`;

export default NotInvitedUsersSheet;
