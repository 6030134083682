import React, { FC, useMemo } from "react";
import { MultiSelect } from "@ui/multi-select";
import { useGetRolesWithEmployeesQuery } from "@components/ui/operations.generated";
import { RecipientRole } from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import { pluralize } from "@utils/strings";
import IdBadgeOutlineIcon from "@src/ui/icons/18px/id-badge-outline";

type Props = {
  roles: RecipientRole[];
  setRoles: (roles: RecipientRole[]) => void;
  showRolesWithNoTrainees?: boolean;
  placeholder?: string;
};

export const RoleMultiSelect: FC<Props> = ({
  roles,
  setRoles,
  showRolesWithNoTrainees = false,
  placeholder = "Search roles...",
}) => {
  const { data } = useGetRolesWithEmployeesQuery();

  const allRoles = useMemo(() => data?.Roles.objects || [], [data]);

  const availableRoles = useMemo(() => {
    let filteredRoles = allRoles;
    if (!showRolesWithNoTrainees) {
      filteredRoles = filteredRoles.filter(
        (role) => role.employees && role.employees.length > 0,
      );
    }
    return filteredRoles;
  }, [allRoles, showRolesWithNoTrainees]);

  const options = useMemo(
    () =>
      availableRoles.map((role) => ({
        label: role.name,
        value: role.id.toString(),
        icon: IdBadgeOutlineIcon,
        subtitle: role.employees
          ? `${role.employees.length} ${pluralize(
              "Trainee",
              role.employees.length,
            )}`
          : undefined,
      })),
    [availableRoles],
  );

  const handleValueChange = (selectedValues: string[]) => {
    const selectedRoles = allRoles.filter((role) =>
      selectedValues.includes(role.id.toString()),
    );
    setRoles(selectedRoles);
  };

  return (
    <MultiSelect
      variant="secondary"
      options={options}
      onValueChange={handleValueChange}
      defaultValue={roles.map((r) => r.id.toString())}
      placeholder={placeholder}
      maxCount={5}
      modalPopover={true}
    />
  );
};

export default RoleMultiSelect;
