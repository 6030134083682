import Widget from "./Widget";
import { StyleSheet } from "aphrodite";
import { gql } from "@apollo/client";
import Text from "@src/ui/text";

import { Skeleton } from "@src/ui/skeleton";
import { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@src/ui/table";
import { ManagerLeaderboardItem_WidgetFragment } from "./ActiveManagersWidget.generated";
import { ActiveAndTotalManagersQuery } from "./ActiveManagersWidget.generated";
import { useActiveAndTotalManagersQuery } from "./ActiveManagersWidget.generated";
import { DateTime } from "luxon";

const ActiveManagersWidget: React.FC = () => {
  const variables = useMemo(() => {
    const activeSince = DateTime.local().minus({ days: 7 }).toISO();
    return { activeSince };
  }, []);
  const { data, loading } = useActiveAndTotalManagersQuery({
    variables,
  });

  return (
    <Widget
      title="Active Managers past 7 days"
      href={{ pathname: "/reporting/manager-leaderboard" }}
    >
      <div className="flex h-full flex-col">
        <div className="flex grow flex-col justify-center">
          <div className="flex flex-row items-end">
            {loading ? (
              <Skeleton className="h-[28px] w-[60px] rounded-md" />
            ) : (
              <Text
                type="H1"
                fontWeight="SemiBold"
                styleDeclaration={styles.text}
              >
                {data?.ActiveManagers?.totalCount}
              </Text>
            )}
            <Text type="P3" fontWeight="SemiBold" className="text-gray-7">
              &nbsp;out of&nbsp;
            </Text>
            {loading ? (
              <Skeleton className="h-[16px] w-[40px] rounded-md" />
            ) : (
              <Text type="P3" fontWeight="SemiBold" className="text-gray-10">
                {data?.TotalManagers?.totalCount}
              </Text>
            )}
          </div>
        </div>
        <div className="mt-auto">
          {loading ? (
            <ManagerLeaderboardWidgetTableSkeleton />
          ) : (
            data?.AssignmentsCompleted && (
              <ManagerLeaderboardWidgetTable data={data.AssignmentsCompleted} />
            )
          )}
        </div>
      </div>
    </Widget>
  );
};

const styles = StyleSheet.create({
  text: {
    lineHeight: "28px",
  },
});

export function ManagerLeaderboardWidgetTable({
  data,
}: {
  data: ActiveAndTotalManagersQuery["AssignmentsCompleted"];
}): JSX.Element {
  const getMedalEmoji = (index: number): string => {
    switch (index) {
      case 0:
        return "🥇";
      case 1:
        return "🥈";
      case 2:
        return "🥉";
      default:
        return `${index + 1}`;
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow className="border-none bg-gray-1">
          <TableHead className="w-[200px] rounded-tl-md text-gray-7">
            Manager
          </TableHead>
          <TableHead className="rounded-tr-md text-right text-gray-7">
            Team Completed Assignments
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.objects?.map?.(
          (
            leaderboardItem: ManagerLeaderboardItem_WidgetFragment,
            index: number,
          ) => {
            if (!leaderboardItem) {
              return null;
            }
            return (
              <TableRow key={leaderboardItem.id}>
                <TableCell className="font-medium">
                  <Text type="P1" fontWeight="Regular" className="mr-2">
                    {getMedalEmoji(index)}
                  </Text>
                  <Text type="P2" fontWeight="Regular">
                    {leaderboardItem.managerName}
                  </Text>
                </TableCell>
                <TableCell className="text-right">
                  <Text type="P2" fontWeight="Regular">
                    {leaderboardItem.teamAssignmentsCompleted}
                  </Text>
                </TableCell>
              </TableRow>
            );
          },
        )}
      </TableBody>
    </Table>
  );
}

// Add this new component for the table skeleton
const ManagerLeaderboardWidgetTableSkeleton: React.FC = () => (
  <Table>
    <TableHeader>
      <TableRow className="border-none bg-gray-1">
        <TableHead className="w-[200px] rounded-tl-md text-gray-7">
          Manager
        </TableHead>
        <TableHead className="rounded-tr-md text-right text-gray-7">
          Team Completed Assignments
        </TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[1, 2, 3].map((index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton className="h-[20px] w-[150px] rounded-md" />
          </TableCell>
          <TableCell className="text-right">
            <Skeleton className="ml-auto h-[20px] w-[40px] rounded-md" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

gql`
  fragment ManagerLeaderboardItem_Widget on ManagerLeaderboardItem {
    id
    managerId
    managerName
    teamAssignmentsCompleted
  }
  query ActiveAndTotalManagers($activeSince: Date!) {
    ActiveManagers: AdminEmployees(
      activeSince: $activeSince
      userTypes: [MANAGER]
    ) {
      totalCount
    }
    TotalManagers: AdminEmployees(userTypes: [MANAGER]) {
      totalCount
    }
    AssignmentsCompleted: ManagerLeaderboardPaginated(
      input: {
        window: WEEK
        sort: { column: "teamAssignmentsCompleted", descending: true }
      }
      pagination: { limit: 3, offset: 0 }
    ) {
      objects {
        ...ManagerLeaderboardItem_Widget
      }
    }
  }
`;

export default ActiveManagersWidget;
