import Widget from "./Widget";
import { Skeleton } from "@src/ui/skeleton";
import { gql } from "@apollo/client";
import { useUserContextState } from "@src/contexts/UserContext";
import { useAvgTrainingFeedbackWidgetQuery } from "./AvgTrainingFeedbackWidget.generated";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import StarOutlineIcon from "@src/ui/icons/18px/star-outline";
import { useMemo } from "react";
import Text from "@src/ui/text";
const chartConfig = {
  average: {
    label: "Average",
  },
};

const AvgTrainingFeedbackWidget: React.FC = () => {
  const { user } = useUserContextState();
  const orgId = user?.org?.id;
  const { loading, data } = useAvgTrainingFeedbackWidgetQuery({
    variables: { orgId: orgId || -1 },
  });
  const formattedData = useMemo(() => data?.AvgTrainingFeedback || [], [data]);
  const average = useMemo(
    () =>
      (
        formattedData.reduce((acc, curr) => acc + curr.average, 0) /
        (formattedData.length || 1)
      ).toFixed(1),
    [formattedData],
  );
  const ticks =
    formattedData && formattedData.length > 0
      ? [formattedData[0].label, formattedData[formattedData.length - 1].label]
      : [];
  return (
    <Widget title="Average Training Feedback" icon={<StarOutlineIcon />}>
      <div>
        <div>
          {loading && <Skeleton className="h-10 w-20 rounded-md" />}
          {!loading && (
            <Text type="H1" fontWeight="SemiBold">
              ⭐️ {average}
            </Text>
          )}
          {loading && <Skeleton className="mt-5 h-[250px] w-full rounded-xl" />}
          {!loading && (
            <ChartContainer
              config={chartConfig}
              className="mt-5 h-[250px] w-full"
            >
              <LineChart
                accessibilityLayer
                data={data?.AvgTrainingFeedback || []}
              >
                <CartesianGrid
                  vertical={true}
                  horizontal={false}
                  strokeDasharray="6 6"
                />
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  xAxisId={0}
                  height={0}
                />
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  ticks={ticks}
                  allowDuplicatedCategory={false}
                  type="category"
                  xAxisId={1}
                  interval="preserveStartEnd"
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent />}
                  isAnimationActive={false}
                />
                <Line
                  dataKey="average"
                  type="linear"
                  strokeWidth={2}
                  dot={false}
                  stroke="#4778F5"
                  isAnimationActive={false}
                />
              </LineChart>
            </ChartContainer>
          )}
        </div>
      </div>
    </Widget>
  );
};

gql`
  query AvgTrainingFeedbackWidget($orgId: Int!) {
    AvgTrainingFeedback(orgId: $orgId) {
      week
      average
      label
    }
  }
`;

export default AvgTrainingFeedbackWidget;
