import AssignmentsListView from "@src/components/my-training/MyTrainingListView";
import OrgDetail from "@components/home/OrgDetail";
import Page, { ActionPermissionString } from "@components/layout/Page";
import useUser from "@hooks/useUser";
import { NextPageWithLayout } from "@pages/_app";
import { UserType } from "@src/types.generated";
import { ReactElement } from "react";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import ManagerHome from "@components/home/ManagerHome";
import AdminHome from "@components/home/AdminHome";

const HomePage: NextPageWithLayout = () => {
  const { user } = useUser();
  const newHomePageEnabled = useFeatureFlag("new-home-page");
  const isTrainee = user?.userType === UserType.Employee;
  if (newHomePageEnabled) {
    if (user?.userType === UserType.Manager) {
      return <ManagerHome />;
    }
    if (user?.userType === UserType.Admin) {
      return <AdminHome />;
    }
  }
  if (isTrainee) {
    return <AssignmentsListView />;
  }
  return <OrgDetail />;
};

const requiredActionPermissions: ActionPermissionString[] = [];
const forbiddenUserTypes: UserType[] = [];

HomePage.getLayout = function getLayout(page: ReactElement) {
  return (
    <Page
      requiredActionPermissions={requiredActionPermissions}
      forbiddenUserTypes={forbiddenUserTypes}
    >
      {page}
    </Page>
  );
};

export default HomePage;
