import Widget from "./Widget";
import PieChartOutlineIcon from "@src/ui/icons/18px/pie-chart-outline";
import { ComplianceType } from "@src/types.generated";
import { useMemo } from "react";
import { Pie, PieChart, Label } from "recharts";
import { Skeleton } from "@src/ui/skeleton";
import { useComplianceSummaryQuery } from "@src/components/reporting/compliance/PremiumPathDetailCertificatesTab.generated";
import { isInFuture } from "@src/components/reporting/compliance/utils";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";

const chartConfig = {
  compliant: {
    label: "Compliant",
    color: "#40D986",
  },
  notCompliant: {
    label: "Not Compliant",
    color: "#9AF4C3",
  },
};

const SHPProgressWidget: React.FC = () => {
  const { data, loading } = useComplianceSummaryQuery({
    variables: {
      input: {
        complianceType: { value: ComplianceType.SexualHarassmentPrevention },
      },
    },
  });

  const complianceRecords = useMemo(
    () => data?.ComplianceRecords?.objects || [],
    [data?.ComplianceRecords?.objects],
  );

  const compliantCount = useMemo(
    () =>
      complianceRecords.filter(
        (x) => isInFuture(x.compliantUntil) && x.compliantUntil,
      )?.length || 0,
    [complianceRecords],
  );

  const notCompliantCount = useMemo(
    () => complianceRecords.length - compliantCount,
    [complianceRecords, compliantCount],
  );

  const chartData = useMemo(() => {
    return [
      {
        status: "compliant",
        label: "Compliant",
        fill: chartConfig.compliant.color,
        count: compliantCount,
      },
      {
        status: "notCompliant",
        label: "Not Compliant",
        fill: chartConfig.notCompliant.color,
        count: notCompliantCount,
      },
    ];
  }, [compliantCount, notCompliantCount]);
  return (
    <Widget
      title="Sexual Harassment Prevention Progress"
      icon={<PieChartOutlineIcon className="text-muted-foreground" />}
    >
      {loading && <Skeleton className="mx-auto size-[192px] rounded-full" />}
      {!loading && (
        <>
          <ChartContainer
            config={chartConfig}
            className="relative mx-auto aspect-square h-[192px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent nameKey="label" hideLabel />}
                isAnimationActive={false}
              />
              <Pie
                isAnimationActive={false}
                data={chartData}
                dataKey="count"
                nameKey="label"
                innerRadius={75}
                outerRadius={"100%"}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="text-[30px] font-bold leading-[24px] tracking-[-0.019em]"
                          >
                            {compliantCount}
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                            className="text-[12px] font-normal leading-[17px] text-muted-foreground"
                          >
                            Compliant
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        </>
      )}
    </Widget>
  );
};

export default SHPProgressWidget;
