import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { LibraryItemDetailPageCoverImage_CoverImageFragmentDoc } from '../libraryItemDetailPages/LibraryItemDetailPageContainer.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminRecentlyEditedPathsQueryVariables = Types.Exact<{
  lastEditedByUserIds?: Types.InputMaybe<Types.IntArrayInput>;
}>;


export type AdminRecentlyEditedPathsQuery = { __typename?: 'Query', AdminLibrary: { __typename?: 'LibraryItems', objects: Array<{ __typename?: 'LibraryItem', id: string, lastEditedAt?: string | null, name: { __typename?: 'TranslationSet', en: string }, path?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, mediaUrl?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null }> } };


export const AdminRecentlyEditedPathsDocument = gql`
    query AdminRecentlyEditedPaths($lastEditedByUserIds: IntArrayInput) {
  AdminLibrary(
    input: {filters: {itemTypes: {value: [PATH]}, lastEditedByUserIds: $lastEditedByUserIds}, sort: {column: "lastEditedAt", descending: true}}
    pagination: {limit: 10}
  ) {
    objects {
      id
      name {
        en
      }
      lastEditedAt
      path {
        id
        libraryItem {
          id
          name {
            en
          }
          coverImage {
            ...LibraryItemDetailPageCoverImage_CoverImage
          }
        }
      }
    }
  }
}
    ${LibraryItemDetailPageCoverImage_CoverImageFragmentDoc}`;

/**
 * __useAdminRecentlyEditedPathsQuery__
 *
 * To run a query within a React component, call `useAdminRecentlyEditedPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRecentlyEditedPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRecentlyEditedPathsQuery({
 *   variables: {
 *      lastEditedByUserIds: // value for 'lastEditedByUserIds'
 *   },
 * });
 */
export function useAdminRecentlyEditedPathsQuery(baseOptions?: Apollo.QueryHookOptions<AdminRecentlyEditedPathsQuery, AdminRecentlyEditedPathsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminRecentlyEditedPathsQuery, AdminRecentlyEditedPathsQueryVariables>(AdminRecentlyEditedPathsDocument, options);
      }
export function useAdminRecentlyEditedPathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminRecentlyEditedPathsQuery, AdminRecentlyEditedPathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminRecentlyEditedPathsQuery, AdminRecentlyEditedPathsQueryVariables>(AdminRecentlyEditedPathsDocument, options);
        }
export type AdminRecentlyEditedPathsQueryHookResult = ReturnType<typeof useAdminRecentlyEditedPathsQuery>;
export type AdminRecentlyEditedPathsLazyQueryHookResult = ReturnType<typeof useAdminRecentlyEditedPathsLazyQuery>;
export type AdminRecentlyEditedPathsQueryResult = Apollo.QueryResult<AdminRecentlyEditedPathsQuery, AdminRecentlyEditedPathsQueryVariables>;