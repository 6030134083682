import React, { FC, useMemo, useState } from "react";
import { useAddUsersToRolesMutation } from "@components/modals/operations.generated";
import { useTracking } from "@hooks/useTracking";
import { AddUsersToRolesInput } from "@src/types.generated";
import { MinimalEmployeeFragment } from "../ui/operations.generated";
import { RecipientRole } from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@ui/dialog";
import { Button } from "@ui/button";
import Text from "@ui/text";
import { UserMultiSelect } from "@components/users/UserMultiSelect";
import RoleMultiSelect from "@src/components/users/RoleMultiSelect";

type Props = {
  data?: AddTraineesToRolesInput;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
  refetchQueries?: string[];
  children?: React.ReactNode;
};

export type AddTraineesToRolesInput = {
  trainees?: MinimalEmployeeFragment[];
  roles?: RecipientRole[];
};

const BulkAddUserRolesDialog: FC<Props> = ({
  data = {},
  open,
  onOpenChange,
  onSuccess,
  refetchQueries = [],
  children,
}) => {
  const initialGroups = useMemo(() => data?.roles || [], [data?.roles]);
  const initialEmployees = useMemo(
    () => data?.trainees || [],
    [data?.trainees],
  );
  const [roles, setRoles] = useState(initialGroups);
  const roleIds = useMemo(() => roles.map((g) => g.id), [roles]);
  const [employees, setEmployees] = useState(initialEmployees);
  const employeeIds = useMemo(
    () => employees.map((e) => e.id.toString()),
    [employees],
  );
  const addTraineesToGroupsInput: AddUsersToRolesInput = {
    roleIds: roleIds,
    userIds: employeeIds.map((id) => parseInt(id, 10)),
  };
  const { trackEvent } = useTracking();
  const [addTraineesToRoles, { loading }] = useAddUsersToRolesMutation({
    variables: {
      input: addTraineesToGroupsInput,
    },
    refetchQueries,
    onCompleted: (data) => {
      if (data.addUsersToRoles.success) {
        if (onSuccess) {
          onSuccess();
        }
        trackEvent("users_added_to_role", addTraineesToGroupsInput);
        onOpenChange(false);
      }
    },
  });
  const handleSave = () => {
    if (roles.length > 0 && employees.length > 0) {
      addTraineesToRoles();
    }
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add roles</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="mb-4">
            <Text type="P2" fontWeight="SemiBold" className="mb-2">
              Users
            </Text>
            <UserMultiSelect
              defaultUserIds={initialEmployees.map((e) => e.id.toString())}
              setEmployees={setEmployees}
              placeholder="Select users..."
            />
          </div>
          <div>
            <Text type="P2" fontWeight="SemiBold" className="mb-2">
              Roles
            </Text>
            <RoleMultiSelect
              roles={roles}
              setRoles={setRoles}
              placeholder="Select roles..."
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={
              loading || roleIds.length === 0 || employeeIds.length === 0
            }
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BulkAddUserRolesDialog;
