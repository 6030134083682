import { FC } from "react";
import Home from "./Home";
import TotalActiveUsersWidget from "./TotalActiveUsersWidget";
import { HomeSection } from "./HomeSection";
import OverviewOfYourLocationsWidgets from "@src/components/home/OverviewOfYourLocationsWidgets";
import AvgTrainingFeedbackWidget from "./AvgTrainingFeedbackWidget";
import SHPProgressWidget from "./SHPProgressWidget";
import LocationAssignmentLeaderboardWidget from "./LocationAssignmentLeaderboardWidget";

const ManagerHome: FC = () => {
  return (
    <Home>
      <div className="flex flex-col gap-6">
        <OverviewOfYourLocationsWidgets />
        <HomeSection title="Usage">
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            <TotalActiveUsersWidget />
          </div>
        </HomeSection>
        <HomeSection title="Assignments">
          <LocationAssignmentLeaderboardWidget />
          <AvgTrainingFeedbackWidget />
          <SHPProgressWidget />
        </HomeSection>
      </div>
    </Home>
  );
};

export default ManagerHome;
