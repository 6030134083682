import Widget from "./Widget";
import { gql } from "@apollo/client";
import { useUserContextState } from "@src/contexts/UserContext";
import { Skeleton } from "@src/ui/skeleton";
import { useAdminRecentlyEditedPathsQuery } from "./RecentlyEditedModulesWidget.generated";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import Text from "@ui/text";
import { Button } from "@src/ui/button";
import Link from "next/link";

const RecentlyEditedModulesWidget: React.FC = () => {
  const { user } = useUserContextState();
  const { data, loading } = useAdminRecentlyEditedPathsQuery({
    variables: { lastEditedByUserIds: { value: [user?.id || -1] } },
  });

  return (
    <Widget title="Your recently edited modules">
      {loading ? (
        <Skeleton className="h-[250px] w-full rounded-xl" />
      ) : (
        <div className="flex flex-row flex-wrap justify-between gap-4">
          {data?.AdminLibrary.objects.map((obj) => (
            <Link
              href={{
                pathname: "/library/library-item/[id]",
                query: { id: obj.path?.libraryItem.id as string },
              }}
              key={obj.id}
            >
              <Button variant="ghost">
                <div key={obj.id} className="flex flex-row gap-2">
                  <ContentAvatar
                    size={"24px"}
                    contentType={"Module"}
                    style={{ borderRadius: 8 }}
                    // coverImage={obj.path?.libraryItem.coverImage}
                    defaultBackgroundHashKey={
                      obj.path?.libraryItem.id as string
                    }
                    coverImage={obj.path?.libraryItem.coverImage}
                  />
                  <Text type="P2">{obj.name.en || "Untitled Module"}</Text>
                </div>
              </Button>
            </Link>
          ))}
        </div>
      )}
    </Widget>
  );
};

gql`
  query AdminRecentlyEditedPaths($lastEditedByUserIds: IntArrayInput) {
    AdminLibrary(
      input: {
        filters: {
          itemTypes: { value: [PATH] }
          lastEditedByUserIds: $lastEditedByUserIds
        }
        sort: { column: "lastEditedAt", descending: true }
      }
      pagination: { limit: 10 }
    ) {
      objects {
        id
        name {
          en
        }
        lastEditedAt
        path {
          id
          libraryItem {
            id
            name {
              en
            }
            coverImage {
              ...LibraryItemDetailPageCoverImage_CoverImage
            }
          }
        }
      }
    }
  }
`;

export default RecentlyEditedModulesWidget;
